// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-career-councelling-jsx": () => import("./../../../src/pages/career_councelling.jsx" /* webpackChunkName: "component---src-pages-career-councelling-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-courses-jsx": () => import("./../../../src/pages/courses.jsx" /* webpackChunkName: "component---src-pages-courses-jsx" */),
  "component---src-pages-esdf-jsx": () => import("./../../../src/pages/esdf.jsx" /* webpackChunkName: "component---src-pages-esdf-jsx" */),
  "component---src-pages-faculties-jsx": () => import("./../../../src/pages/faculties.jsx" /* webpackChunkName: "component---src-pages-faculties-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-future-content-jsx": () => import("./../../../src/pages/future_content.jsx" /* webpackChunkName: "component---src-pages-future-content-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-management-jsx": () => import("./../../../src/pages/management.jsx" /* webpackChunkName: "component---src-pages-management-jsx" */),
  "component---src-pages-noticeboard-jsx": () => import("./../../../src/pages/noticeboard.jsx" /* webpackChunkName: "component---src-pages-noticeboard-jsx" */),
  "component---src-pages-rules-jsx": () => import("./../../../src/pages/rules.jsx" /* webpackChunkName: "component---src-pages-rules-jsx" */)
}

